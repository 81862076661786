import useInput from '../hooks/use-input'

const Form = () => {
  const nameFormControl = useInput(val => val.trim().length !== 0)
  const emailFormControl = useInput(val => val.includes('@'))


  const formValid = nameFormControl.isValid && emailFormControl.isValid;

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (!formValid) {
      return;
    }
    console.log(nameFormControl.value, emailFormControl.value);
    nameFormControl.reset();
    emailFormControl.reset();
  };


  return (
    <form onSubmit={formSubmitHandler}>
      <div>
        <label htmlFor="name">Name</label>
        <input
          onBlur={nameFormControl.inputBlurHandler}
          onChange={nameFormControl.valueChangeHandler}
          value={nameFormControl.value}
          id="name"
          type="text"
        ></input>
        {nameFormControl.hasError && <p>Invalid name</p>}
      </div>

      <div>
        <label htmlFor="email">Email</label>
        <input
          onBlur={emailFormControl.inputBlurHandler}
          onChange={emailFormControl.valueChangeHandler}
          value={emailFormControl.value}
          id="email"
          type="email"
        ></input>
        {emailFormControl.hasError && <p>Invalid email</p>}
      </div>
      <button disabled={!formValid} type="submit">Send</button>
    </form>
  );
};

export default Form;
